const shareNavigator = () => {
  if (navigator.share) {
    navigator
      .share({
        title:
          'Mit unserer mobilen Schnitzeljagd wird der ZÄPFLE Weg zum Quiz. Wie gut würdest du abschneiden?',
        url: 'https://www.rothaus.de/genusswelt/zaepfle-weg',
        text: '',
      })
      .then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
  }
};

export default shareNavigator;
