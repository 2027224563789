import React from 'react';

import s from './styles.module.scss';

const TextFullScreen = ({ data }) => {
  const { description, height } = data;
  const style = {
    height: height + '%',
  };

  return (
    <div style={style} className={s['text-full-screen']}>
      <div className='app-container'>
        {description && (
          <div
            className={`${s['text-full-screen__description']}`}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </div>
  );
};

export default TextFullScreen;
