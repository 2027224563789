import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MainMenuLayout from '@l/MainMenuLayout';
import BottomModal from '@c/BottomModal';
import Settings from '@c/Settings';
// Actions
import { changeCurrentPage } from '@s/store/routing/actions';
import { enableNavigationPanel } from '@s/store/routing/actions';
import { fetchChaptersAsync } from '@s/store/chapters/actions';

const MainMenu = () => {
  const [showModal, setShowModal] = React.useState(false);
  const dispatch = useDispatch();
  const startData = useSelector((state) => state.startData).toJS();
  const {
    startData: { acf },
  } = startData;

  const handleClickCloseModal = () => {
    setShowModal(false);
  };

  const {
    main_menu_heading,
    main_menu_button_1,
    main_menu_button_2,
    main_menu_button_3,
    settings_heading,
  } = acf;

  const buttons = [
    {
      text: main_menu_button_1,
      clickFunc: () => dispatch(changeCurrentPage('start')),
    },
    {
      text: main_menu_button_2,
      clickFunc: () => dispatch(changeCurrentPage('statistic')),
    },
    {
      text: main_menu_button_3,
      clickFunc: () => setShowModal(true),
    },
  ];

  useEffect(() => {
    dispatch(fetchChaptersAsync());
    dispatch(enableNavigationPanel());
  }, []);

  return (
    <>
      <MainMenuLayout heading={main_menu_heading} buttons={buttons} langs={true} />
      <BottomModal closeModalFunc={handleClickCloseModal} isShowModal={showModal}>
        {settings_heading && <h1 className='h2'>{settings_heading}</h1>}
        <Settings />
      </BottomModal>
    </>
  );
};

export default MainMenu;
