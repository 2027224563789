import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { saveGameResults, saveAllPoints, saveRightPoints } from '@s/store/app-data/actions';
import { showModalBeforeRedirect } from '@s/store/routing/actions';
import { changeCurrentScreen } from '@s/store/routing/actions';

import s from './styles.module.scss';

const TextInput = ({ id, data, points }) => {
  const dispatch = useDispatch();
  const [answer, setAnswer] = React.useState('');
  const [checkAnswer, setCheckAnswer] = React.useState(null);
  const appData = useSelector((state) => state.appData).toJS();
  const { screenNext } = useSelector((state) => state.navigation).toJS();

  useEffect(() => {
    const textInputData = appData.gamesResults[id];

    if (textInputData) {
      setAnswer(textInputData.answerText);
      setCheckAnswer(true);
    }

    dispatch(saveAllPoints({ gameId: id, points }));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // data.answer

    if (answer === data.answer && data.is_required === 'yes') {
      setCheckAnswer(true);

      const answerObj = {
        [id]: {
          type: 'Text Input',
          isRight: true,
          answerText: answer,
          isAnswer: false,
        },
      };
      dispatch(saveGameResults(answerObj));
    } else if (data.is_required === 'no') {
      const isRight = answer === data.answer;
      setCheckAnswer(isRight);

      const rPoints = isRight ? points : 0;

      const answerObj = {
        [id]: {
          type: 'Text Input',
          isRight,
          answerText: answer,
          isAnswer: false,
          allPoints: points,
          rightPoints: rPoints,
        },
      };
      dispatch(saveGameResults(answerObj));
      dispatch(saveRightPoints({ gameId: id, points: rPoints }));
    } else {
      setCheckAnswer(false);
    }

    if (answer === data.answer) {
      setTimeout(() => {
        dispatch(showModalBeforeRedirect(true));

        const answerObj = {
          [id]: {
            type: 'Text Input',
            isRight: true,
            answerText: answer,
            isAnswer: true,
            allPoints: points,
            rightPoints: points,
          },
        };

        dispatch(saveGameResults(answerObj));
        dispatch(saveRightPoints({ gameId: id, points }));
      }, 1000);

      setTimeout(() => {
        dispatch(showModalBeforeRedirect(false));

        dispatch(changeCurrentScreen(screenNext));
      }, 3000);
    }
  };

  const handleChange = (e) => {
    setAnswer(e.target.value);
  };

  let inputClasses = `${s['text-input__form-input']} app-input ${
    checkAnswer === false ? 'app-input--error' : ''
  }`;

  return (
    <div className={s['text-input']}>
      {/* <GameHeader heading={data.heading} description={data.description} /> */}

      <div className={s['text-input__form']}>
        {/* <ZapfenImage className={s['text-input__zapfen']} /> */}

        <form action='' onSubmit={handleSubmit}>
          <div className='app-container'>
            <div className={s['text-input__form-container']}>
              <label>Deine Antwort</label>
              <input
                value={answer}
                readOnly={checkAnswer}
                name='answer'
                onChange={handleChange}
                className={inputClasses}
                type='text'
              />
              <button
                disabled={answer.trim() === ''}
                type='submit'
                className={`app-button ${checkAnswer ? 'app-button--hidden' : ''}`}>
                Weiter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TextInput;
