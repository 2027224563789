import React from 'react';

// Components
import TopModal from '../TopModal';

// Styles
import s from './styles.module.scss';

const LinkPopup = ({ data }) => {
  const [showModal, setShowModal] = React.useState(false);

  const { link_label, title_in_modal, text_in_modal } = data;

  const handleClickButton = () => {
    setShowModal(true);
  };

  const handleClickCloseButton = () => {
    setShowModal(false);
  };

  const style = {
    height: data.height + '%',
  };

  return (
    <div style={style} className={s['link-popup']}>
      <button onClick={handleClickButton} className={s['link-popup__label']}>
        {link_label}
      </button>

      <TopModal
        title={title_in_modal}
        description={text_in_modal}
        buttons={
          <button onClick={handleClickCloseButton} type='button' className='app-button'>
            {link_label}
          </button>
        }
        isShowModal={showModal}
        closeModalFunc={handleClickCloseButton}
      />
    </div>
  );
};

export default LinkPopup;
