import { Map, fromJS } from 'immutable';

import { CHANGE_APP_DATA, CLEAR_APP_DATA } from './types';

const initialState = Map({
  userId: null,
  is_years: false,
  is_agb: false,
  currentChapter: 1,
  currentChapterId: null,
  completedChapters: [],
  currentStep: 1,
  gamesResults: {},
  animatedStep: {},
  completedDate: null,
  pointsAll: 0,
  pointsRight: 0,
  visitedGames: {},
  chapterReached: 1,
  stepReached: 1,
});

export const appDataReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_APP_DATA:
      return fromJS(payload);
    case CLEAR_APP_DATA:
      const clearState = { ...initialState.toJS() };
      delete clearState.userId;
      delete clearState.is_years;
      delete clearState.is_agb;

      return fromJS({ ...state.toJS(), ...clearState });

    default:
      return state;
  }
};
