import React from 'react';

import s from './styles.module.scss';
import { ReactComponent as BiergitImage } from '@img/Biergit.svg';
import { ReactComponent as ZapfenImage } from '@img/ZapfenBig.svg';
// import LanguageSwitcher from '@c/LanguageSwitcher';

const MainMenuLayout = ({ buttons, heading, start, langs }) => {
  return (
    <div className={s['main-menu-layout']}>
      <div
        className={`${s['main-menu-layout__hero']} ${
          start ? s['main-menu-layout__hero--border'] : ''
        } app-container`}>
        {heading && <h1 className={s['main-menu-layout__heading']}>{heading}</h1>}
        {!start && <BiergitImage className={s['main-menu-layout__biergit']} />}
        {start && <ZapfenImage className={s['main-menu-layout__zapfen']} />}
      </div>

      <div className={`${s['main-menu-layout__navigation']} app-container`}>
        {buttons &&
          buttons.map((button, i) => (
            <button
              className='app-button app-button--outline'
              key={i}
              onClick={() => button.clickFunc()}>
              {button.text}
            </button>
          ))}
        {/* {langs && <LanguageSwitcher />} */}
      </div>
    </div>
  );
};

export default MainMenuLayout;
