import { setCookie } from '@u/cookie';
import { pushGtmEvent } from '@u/gtmEvents';

// Types
import { CHANGE_APP_DATA, CLEAR_APP_DATA } from './types';

const expires = { expires: 32503672800 };

// export const changeAppData = (data) => {
//   return {
//     type: CHANGE_APP_DATA,
//     payload: data
//   };
// };

export const saveGameResults = (data) => async (dispatch, getState) => {
  const { appData } = getState();
  const appDataJs = appData.toJS();

  const newAppData = {
    ...appDataJs,
    gamesResults: { ...appDataJs.gamesResults, ...data },
  };

  dispatch({
    type: CHANGE_APP_DATA,
    payload: newAppData,
  });
};

export const saveAllPoints =
  ({ points, gameId }) =>
  async (dispatch, getState) => {
    const { appData } = getState();
    const appDataJs = appData.toJS();
    const visitedGames = appDataJs.visitedGames;

    if (!(gameId in visitedGames)) {
      const obj = {
        all: Number(points),
        right: 0,
      };
      visitedGames[gameId] = obj;

      let pointsAll = 0;

      for (const key in visitedGames) {
        pointsAll += visitedGames[key].all;
      }

      const newAppData = {
        ...appDataJs,
        pointsAll,
        visitedGames,
      };

      dispatch({
        type: CHANGE_APP_DATA,
        payload: newAppData,
      });
    }
  };
export const saveRightPoints =
  ({ points, gameId }) =>
  async (dispatch, getState) => {
    const { appData } = getState();
    const appDataJs = appData.toJS();
    const visitedGames = appDataJs.visitedGames;

    if (gameId in visitedGames && !appDataJs.gamesResults[gameId].isAnswer) {
      const newObj = { ...visitedGames[gameId] };
      newObj.right = Number(points);
      const newVisitedGames = { ...visitedGames, ...{ [gameId]: newObj } };

      let pointsRight = 0;

      for (const key in newVisitedGames) {
        pointsRight += newVisitedGames[key].right;
      }

      const newAppData = {
        ...appDataJs,
        pointsRight,
        visitedGames: newVisitedGames,
      };

      dispatch({
        type: CHANGE_APP_DATA,
        payload: newAppData,
      });
    }
  };

export const setReachedData = (data) => async (dispatch, getState) => {
  const { appData } = getState();
  const appDataJs = appData.toJS();

  const mes = `C${data.chapterReached}S${data.stepReached}`;
  pushGtmEvent({
    event: 'StepReached',
    value: mes,
  });
  const newAppData = {
    ...appDataJs,
    chapterReached: data.chapterReached,
    stepReached: data.stepReached,
  };

  dispatch({
    type: CHANGE_APP_DATA,
    payload: newAppData,
  });
};

export const changeAppData = (data) => async (dispatch) => {
  setCookie('appData', JSON.stringify(data), expires);

  dispatch({
    type: CHANGE_APP_DATA,
    payload: data,
  });
};

export const clearAppData = () => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_APP_DATA,
  });

  setCookie('appData', JSON.stringify(getState().appData.toJS()), expires);
};
