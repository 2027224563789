import React from 'react';

import s from './styles.module.scss';

import { ReactComponent as OrientationIcon } from '@img/orientation.svg';

const DesktopPlaceholder = ({ message }) => {
  return (
    <div className={s['rotate-placeholder']}>
      <div className={s['rotate-placeholder__wrapper']}>
        <h1 className={s['rotate-placeholder__heading']}>{message}</h1>
        <OrientationIcon className={s['rotate-placeholder__icon']} />
      </div>
    </div>
  );
};

export default DesktopPlaceholder;
