import React from 'react';

// Styles
import s from './styles.module.scss';

import TopIcon from '@img/message/4.svg';
import BottomIcon from '@img/message/5.svg';

const SpeechBubble = ({ data }) => {
  const { height, message } = data;

  const style = {
    height: height + '%',
  };

  return (
    <div className={s['speech-bubble']} style={style}>
      <div className='app-container'>
        <div className={s['speech-bubble__message']}>
          {/* <img className={s['speech-bubble__message-icon-top']} src={TopIcon} alt='TopIcon' /> */}
          <div className={s['speech-bubble__message-body']}>
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </div>
          {/* <img
            className={s['speech-bubble__message-icon-bottom']}
            src={BottomIcon}
            alt='BottomIcon'
          /> */}
          <div className={s['speech-bubble__message-icon-triangle']}></div>
        </div>
      </div>
    </div>
  );
};

export default SpeechBubble;
