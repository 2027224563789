import React, { useEffect, useState } from 'react';
import Map from '../Map';

// import GameHeader from '../GameHeader';

import s from './styles.module.scss';

const Geolocation = ({ data, id, points }) => {
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);

  const onScriptload = () => {
    let s = document.createElement('script');
    s.setAttribute('id', 'google-map-api');
    s.src =
      'https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=AIzaSyCB2O0YA7NHr_KWHy0Z_Izx_wjM2FtqMsI&v=3';
    document.body.appendChild(s);
    s.addEventListener('load', function () {
      setGoogleMapsLoaded(!googleMapsLoaded);
    });
  };

  useEffect(() => {
    if (!document.getElementById('google-map-api')) {
      onScriptload();
    } else {
      setGoogleMapsLoaded(!googleMapsLoaded);
    }
  }, []);

  if (!googleMapsLoaded) {
    // return <div>Loading...</div>;
    return false;
  } else {
    const destination = [data.checkpoint.lat, data.checkpoint.lng];

    return (
      <div className={s['game-geolocation']}>
        {/* <GameHeader heading={data.heading} description={data.description} /> */}
        <Map
          id={id}
          googleMapsLoaded={googleMapsLoaded}
          destination={destination}
          dataGame={data}
          points={points}
        />
      </div>
    );
  }
};

export default Geolocation;
