import React from 'react';

import s from './styles.module.scss';

const ProcentsCircle = ({ percentage }) => {
  const [calcProcents, setCalcProcents] = React.useState(0);

  React.useEffect(() => {
    let chagePercentage = setTimeout(() => {
      if (calcProcents < percentage) {
        setCalcProcents(calcProcents + 1);
      }
    }, 10);

    return () => {
      clearTimeout(chagePercentage);
    };
  }, [calcProcents]);

  const sqSize = 155;
  const strokeWidth = 10;
  const radius = (sqSize - strokeWidth) / 2;
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <div className={s['progress-loader']}>
      <svg width={sqSize} height={sqSize} viewBox={viewBox}>
        <circle
          className={s['progress-loader__background']}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          className={s['progress-loader__progress']}
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
        <text className={s['progress-loader__text']} x='50%' y='50%' dy='.3em' textAnchor='middle'>
          {`${calcProcents}%`}
        </text>
      </svg>
    </div>
  );
};

ProcentsCircle.defaultProps = {
  percentage: 0,
};

export default ProcentsCircle;
