import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WhiteLayout from '@l/WhiteLayout';

// Actions
import { changeCurrentPage } from '@s/store/routing/actions';

const Hello = () => {
  const dispatch = useDispatch();
  const startData = useSelector((state) => state.startData).toJS();
  const {
    startData: { acf },
  } = startData;

  const { hello_heading, hello_image, hello_description, hello_button_name } = acf;

  const handleOnClickButton = () => dispatch(changeCurrentPage('you-years'));

  return (
    <WhiteLayout
      heading={hello_heading && <h1>{hello_heading}</h1>}
      image={hello_image && <img src={hello_image.url} alt={hello_image.alt} />}
      description={hello_description}
      buttons={hello_button_name && <button onClick={() => handleOnClickButton()} type='button' className="app-button">{hello_button_name}</button>}
    />
  );
};

export default Hello;
