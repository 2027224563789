import React from 'react';

// Styles
import s from './styles.module.scss';

// Images
import { ReactComponent as MessageImage } from '@img/message.svg';
import { ReactComponent as Biergit } from '@img/Biergit.svg';

const BirgitMessage = ({ data }) => {
  // const [currentTime, setCurrentTime] = React.useState(0);
  const { height, message } = data;

  const style = {
    height: height + '%',
  };

  return (
    <div className={s['birgit-message']} style={style}>
      <div className='app-container'>
        <div className={s['birgit-message__message-wrapper']}>
          <div className={s['birgit-message__message']}>
            <MessageImage />
            <div className={s['birgit-message__message-text']}>
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </div>
          </div>
        </div>

        <div className={s['birgit-message__biergit']}>
          <Biergit />
        </div>
      </div>
    </div>
  );
};

export default BirgitMessage;
