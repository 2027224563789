import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { changeCurrentPage, changeCurrentStep } from '@s/store/routing/actions';

// Icons
import { ReactComponent as LockedStepIcon } from '@img/LockedStep.svg';
import { ReactComponent as UnlockedStepIcon } from '@img/UnlockedStep.svg';
import { changeAppData, setReachedData } from '@s/store/app-data/actions';

// Styles
import s from './styles.module.scss';

const Steps = ({ steps, chapter, currentStep, isPassedChapter }) => {
  const dispatch = useDispatch();
  const appData = useSelector((state) => state.appData).toJS();
  const chapterStepsRef = React.useRef(null);

  const handleClickButtonStep = (step_number) => {
    dispatch(changeCurrentPage('step'));
    dispatch(changeCurrentStep(step_number));
  };

  useEffect(() => {
    if (currentStep - 1 > 0) {
      let arrSteps;
      if (!appData.animatedStep[appData.currentChapter]) {
        arrSteps = [currentStep - 1];
      } else {
        arrSteps = appData.animatedStep[appData.currentChapter];
        arrSteps.push(currentStep - 1);
      }
      const newAnimatedStep = {
        ...appData.animatedStep,
        [appData.currentChapter]: arrSteps,
      };

      const newAppData = {
        ...appData,
        animatedStep: newAnimatedStep,
      };

      dispatch(changeAppData(newAppData));
    }

    let aotoMoveToStep;

    aotoMoveToStep = setTimeout(() => {
      handleClickButtonStep(currentStep);
    }, 3500);

    let topCurrentStep = document.querySelector('[data-current="true"]').offsetTop;
    chapterStepsRef.current.scrollTo(0, topCurrentStep);

    // if (appData.currentChapter >= appData.chapterReached && currentStep > appData.stepReached) {
    dispatch(setReachedData({ stepReached: currentStep, chapterReached: appData.currentChapter }));
    // }

    return () => {
      clearTimeout(aotoMoveToStep);
    };
  }, []);

  if (!steps.length) {
    return false;
  }

  return (
    <div className={s['chapter-steps']}>
      <div ref={chapterStepsRef} className={s['chapter-steps__wrapper']}>
        {steps.reverse().map((step, i) => {
          const stepNumber = steps.length - i;

          let itemClass = '';
          let showNumber = true;
          let isAnimationLocked = false;
          if (stepNumber < currentStep || isPassedChapter) {
            itemClass = s['chapter-steps__item--passed'];
          } else if (stepNumber === currentStep) {
            itemClass = s['chapter-steps__item--unlocked'];
          } else {
            showNumber = false;
            itemClass = s['chapter-steps__item--locked'];
          }

          if (appData.animatedStep[appData.currentChapter]) {
            if (appData.animatedStep[appData.currentChapter].indexOf(stepNumber) !== -1) {
              if (currentStep - 1 === stepNumber) {
                itemClass = s['chapter-steps__item--animation'];
              }
            } else {
              if (currentStep === stepNumber) {
                isAnimationLocked = true;
                itemClass = `${s['chapter-steps__item--animation-locked']} ${s['chapter-steps__item--locked']}`;
              }
            }
          }

          let dataCurrent = stepNumber === currentStep;

          return (
            <div
              data-current={dataCurrent}
              key={stepNumber}
              className={`${s['chapter-steps__item']} ${itemClass}`}>
              <button
                onClick={() => {
                  showNumber && handleClickButtonStep(stepNumber);
                }}
                type='button'
                key={i}
                className={s['chapter-steps__item-button']}>
                {showNumber && <UnlockedStepIcon className={s['chapter-steps__item-icon']} />}
                {isAnimationLocked && (
                  <LockedStepIcon
                    className={`${s['chapter-steps__item-icon']} ${s['chapter-steps__item-icon--animatiom-locked']}`}
                  />
                )}
                {!showNumber && <LockedStepIcon className={s['chapter-steps__item-icon']} />}
                {showNumber && (
                  <span className={s['chapter-steps__item-number']}>{stepNumber}</span>
                )}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Steps;
