import { combineReducers } from 'redux';

import { gameReducer } from '@s/store/game/reducer';
import { chaptersReducer } from '@s/store/chapters/reducer';
import { chapterReducer } from '@s/store/chapter/reducer';
import { navigationReducer } from '@s/store/navigation/reducer';
import { routingReducer } from '@s/store/routing/reducer';
import { startDataReducer } from '@s/store/start-data/reducer';
import { appDataReducer } from '@s/store/app-data/reducer';
import { appDataSessionReducer } from '@s/store/app-data-session/reducer';
import { animations } from '@s/store/animations/reducer';
import { submitFormReducer } from '@s/store/submit-form/reducer';

export const rootReducer = combineReducers({
  game: gameReducer,
  chapters: chaptersReducer,
  chapter: chapterReducer,
  navigation: navigationReducer,
  routing: routingReducer,
  startData: startDataReducer,
  appData: appDataReducer,
  appDataSession: appDataSessionReducer,
  animations,
  submitForm: submitFormReducer,
});
