import React from 'react';

import s from './styles.module.scss';

import { ReactComponent as LogoWomanImage } from '@img/LogoWoman.svg';

const DesktopPlaceholder = ({ message }) => {
  return (
    <div className={s['desktop-placeholder']}>
      <h1 className={s['desktop-placeholder__heading']}>{message}</h1>
      <LogoWomanImage className={s['desktop-placeholder__logo']} />
    </div>
  );
};

export default DesktopPlaceholder;
