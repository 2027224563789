import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import WhiteLayout from '@l/WhiteLayout';

// Actions
import { changeCurrentPage } from '@s/store/routing/actions';

const Agbs = () => {
  const dispatch = useDispatch();
  const startData = useSelector((state) => state.startData).toJS();

  const {
    startData: { acf },
  } = startData;

  const { privacy_policy_text } = acf;

  const handleClickBackButton = () => {
    dispatch(changeCurrentPage('main-menu'));
  };

  return (
    <WhiteLayout
      enableLandscape={true}
      description={privacy_policy_text}
      backBattonFunc={handleClickBackButton}></WhiteLayout>
  );
};

export default Agbs;
