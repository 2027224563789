import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSprings, animated, to } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import move from 'lodash-move';

// Actions
import { changeCurrentPage, changeCurrentChapter } from '@s/store/routing/actions';
import { setReachedData } from '@s/store/app-data/actions';

import { ReactComponent as BlockedIcon } from '@img/Blocked.svg';

import s from './styles.module.scss';

const ChapterCards = ({ chapters, completedChapters, currentChapterId }) => {
  const dispatch = useDispatch();
  const ref = React.useRef(null);
  const appData = useSelector((state) => state.appData).toJS();
  const handleClickChartButton = (id) => {
    dispatch(changeCurrentChapter(id));
    dispatch(changeCurrentPage('chapter'));
  };

  const config = { tension: 300, friction: 80 };
  const CARD_COUNT = chapters.length;
  const CARD_OFFSET = -60;

  const setScale = (count) => 1 - count / 20;

  const setter = (order, curIndex, y, down) => (index2) => {
    const originIndex = index2;
    const index = order.indexOf(index2);
    const isBelow = y < -100; // CARD_COUNT * CARD_OFFSET
    if (y === 0) {
      return false;
    }

    let positionCard;
    if (down && index === curIndex) {
      positionCard = {
        scale: isBelow ? setScale(CARD_COUNT) : 1,
        y,
        zIndex: isBelow ? 1 : CARD_COUNT + 1,
        config,
        immediate: (key) => key === 'y' || key === 'zIndex',
      };
    } else {
      let offset = 0;
      let scale = 0;
      const isPassedChapter = completedChapters.indexOf(chapters[originIndex].id) !== -1;

      if (isPassedChapter) {
        const offsetIndex = originIndex + 1;
        if (offsetIndex < CARD_COUNT) {
          offset = -CARD_OFFSET * offsetIndex;
          scale = offsetIndex;
        }
      } else {
        offset = CARD_OFFSET * index;
        scale = index;
      }

      // if (index === 0) {
      //   offset = 0;
      //   scale = 0;
      // }

      positionCard = {
        scale: setScale(scale),
        y: offset,
        // Add 1 here so we don’t need to use 0 above.
        zIndex: CARD_COUNT - index + 1,
        immediate: (key) => key === 'zIndex',
      };
    }

    return positionCard;
  };

  const cardOrder = React.useRef(chapters.map((_, index) => index));
  const indexCurrentChupter = chapters.findIndex((i) => i.id === currentChapterId);
  let startOrder = [];

  if (indexCurrentChupter > 0) {
    // startOrder = move(cardOrder.current, indexCurrentChupter - 1, CARD_COUNT);
    // startOrder = move(cardOrder.current, indexCurrentChupter, 0);
    let currentOrder = [...cardOrder.current];
    let newOrder = [];
    for (let i = 0; i < CARD_COUNT; i++) {
      if (indexCurrentChupter + i < CARD_COUNT) {
        let newIndex = indexCurrentChupter + i;
        newOrder.push(newIndex);
        const index = currentOrder.indexOf(newIndex);
        if (index > -1) {
          currentOrder.splice(index, 1);
        }
      }
    }
    startOrder = [...newOrder, ...currentOrder];
  } else {
    startOrder = cardOrder.current;
  }

  const [springs, setSprings] = useSprings(CARD_COUNT, setter(startOrder));

  // useEffect(() => {
  //   // if (
  //   //   appData.currentChapter >= appData.chapterReached &&
  //   //   Number(appData.currentChapter) <= chapters.length
  //   // ) {
  //   // dispatch(
  //   //   setReachedData({
  //   //     stepReached: appData.currentStep,
  //   //     chapterReached: appData.currentChapter,
  //   //   })
  //   // );
  //   // }
  // }, []);

  useEffect(() => {
    setSprings.start(setter(startOrder));
  }, [startOrder, indexCurrentChupter]);

  const bind = useDrag(
    (event) => {
      // console.log('----------------');
      const {
        args: [origlIndex],
        down,
        movement: [, y],
      } = event;

      if (y === 0) {
        return false;
      }

      const curIndex = startOrder.indexOf(origlIndex);
      const isPassedChapter = completedChapters.indexOf(chapters[origlIndex].id) !== -1;
      const isAvailable =
        completedChapters.find((el) => el === chapters[origlIndex].id) ||
        currentChapterId === chapters[origlIndex].id;
      if (
        !down &&
        document.querySelector('[data-id="' + chapters[origlIndex].id + '"]' + ' [data-passed]')
      ) {
        if (isPassedChapter && origlIndex === curIndex) {
          document
            .querySelector('[data-id="' + chapters[origlIndex].id + '"]' + ' [data-passed]')
            .setAttribute('data-passed', 'true');
        } else {
          document
            .querySelector('[data-id="' + chapters[origlIndex].id + '"]' + ' [data-passed]')
            .setAttribute('data-passed', 'false');
        }
      }

      // if (!down && Math.abs(y) > 20 && isPassedChapter) {
      //   // const newOrder = move(startOrder, indexCurrentChupter, CARD_COUNT);

      //   let newOrder;
      //   if (isPassedChapter) {
      //     newOrder = move(cardOrder.current, curIndex, CARD_COUNT);
      //   } else {
      //     newOrder = move(startOrder, curIndex, CARD_COUNT);
      //   }

      //   startOrder = newOrder;
      // }

      if (!down && Math.abs(y) > 20 && (isPassedChapter || isAvailable)) {
        // const newOrder = move(startOrder, indexCurrentChupter, CARD_COUNT);

        let newOrder = [];
        let currentOrder = [];
        currentOrder = [...cardOrder.current];
        for (let i = 0; i < CARD_COUNT; i++) {
          if (curIndex + i < CARD_COUNT) {
            let newIndex = origlIndex + i;
            newOrder.push(newIndex);
            const index = currentOrder.indexOf(newIndex);
            if (index > -1) {
              currentOrder.splice(index, 1);
            }
          }
        }

        startOrder = [...newOrder, ...currentOrder];
        // console.log(startOrder);
      }

      setSprings.start(setter(startOrder, curIndex, y, down));

      // const curIndex = cardOrder.current.indexOf(origlIndex);
      // const newOrder = move(cardOrder.current, 0, CARD_COUNT);

      // if (!down && Math.abs(y)>40) {
      //   cardOrder.current = newOrder;
      // }

      // setSprings.start(setter(cardOrder.current, curIndex, y, down));
    },
    { useTouch: true }
  );

  return (
    <div className={`${s['chapter-cards']} app-container`}>
      <div className={s['chapter-cards__items']} ref={ref}>
        {springs.map(({ scale, y, zIndex }, index) => {
          const { id, title, acf } = chapters[index];
          const { description, picture, short_name } = acf;

          const isAvailable = completedChapters.find((el) => el === id) || currentChapterId === id;

          const completedChaptersArr = completedChapters.find((el) => el === id);
          const isPassedChapter = completedChaptersArr ? true : false;

          return (
            <animated.div
              {...bind(index)}
              style={{
                cursor: 'grab',
                left: 0,
                // paddingTop: CARD_RATIO,
                position: 'absolute',
                right: 0,
                // transform: to([y, scale], (y, scale) => `translateY(${y}px) scale(${scale})`),
                transform: to(
                  [y, scale],
                  (y, scale) => `translateY(calc(${y / 8} * var(--vh))) scale(${scale})`
                ),
                // transformOrigin: 'top center',
                zIndex,
                opacity: 0,
                animationDelay: `${index * 0.3}s`,
              }}
              data-id={id}
              key={id}
              data-passed-card={isPassedChapter}>
              <div key={id} className={s['chapter-cards__item']}>
                {isAvailable && (
                  <div key={id} className={s['chapter-cards__header']}>
                    {title && (
                      <div className={`${s['chapter-cards__title']} h2`}>{title.rendered}</div>
                    )}
                    {!isAvailable && <BlockedIcon />}
                  </div>
                )}
                {!isAvailable && (
                  <div key={id} className={s['chapter-cards__header-blocked']}>
                    {short_name && (
                      <h2 className={`${s['chapter-cards__title']} h2`}>{short_name}</h2>
                    )}
                    <BlockedIcon />
                  </div>
                )}
                {!isAvailable && <BlockedIcon className={s['chapter-cards__blocked-content']} />}
                {isAvailable && (
                  <div data-passed={isPassedChapter} className={s['chapter-cards__content']}>
                    {picture && (
                      <img
                        className={s['chapter-cards__picture']}
                        src={picture.sizes.medium_large}
                      />
                    )}
                    {description && (
                      <div
                        className={s['chapter-cards__description']}
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    )}
                    {isAvailable && (
                      <>
                        <button
                          onClick={() => {
                            handleClickChartButton(id);
                          }}
                          type='button'
                          className='app-button'>
                          Jetzt erleben
                        </button>
                        <h2 className={`${s['chapter-cards__passed-title']} h2`}>{short_name}</h2>
                      </>
                    )}
                  </div>
                )}
              </div>
            </animated.div>
          );
        })}
      </div>
    </div>
  );
};

export default ChapterCards;
