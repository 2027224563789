import React from 'react';

const getOrientation = () => window.orientation;

const useScreenOrientation = () => {
  // save current window width in the state object
  let [orientation, setOrientation] = React.useState(getOrientation());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  React.useEffect(() => {
    // timeoutId for debounce mechanism
    // let timeoutId = null;
    const orientationListener = () => {
      // // prevent execution of previous setTimeout
      // clearTimeout(timeoutId);
      // // change width from the state object after 150 milliseconds
      // timeoutId = setTimeout(() => setWidth(getWidth()), 150);\setOrientation
      setOrientation(getOrientation());
    };

    window.addEventListener('orientationchange', orientationListener);
    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('orientationchange', orientationListener);
    };
  }, []);

  if (orientation !== 0) {
    return 'horizontal';
  } else {
    return 'vertical';
  }
};

export default useScreenOrientation;
