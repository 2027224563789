import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pushGtmEvent } from '@u/gtmEvents';

import MainLayout from '@l/MainLayout';

// Actions
import { clearAppData } from '@s/store/app-data/actions';
import {
  changeCurrentPage,
  disableNavigationPanel,
  enableNavigationPanel,
  clearRouting,
} from '@s/store/routing/actions';

import shareNavigator from '@u/shareNavigator';

import s from './styles.module.scss';

const Congratulations = () => {
  const dispatch = useDispatch();
  const startData = useSelector((state) => state.startData).toJS();
  const appData = useSelector((state) => state.appData).toJS();
  const chapters = useSelector((state) => state.chapters).toJS();

  const {
    startData: { acf },
  } = startData;

  let countRightAnswers = 0;
  for (const key in appData.gamesResults) {
    if (appData.gamesResults[key].isRight) {
      countRightAnswers++;
    }
  }

  React.useEffect(() => {
    dispatch(disableNavigationPanel());
  }, []);

  const {
    сongratulations_heading,
    сongratulations_image,
    сongratulations_description,
    button_play_again,
    button_share,
    button_statistics,
    button_to_the_menu,
    count_right_answers,
  } = acf;

  const buttons = [
    {
      text: button_play_again,
      modification: 'app-button--yellow',
      clickFunc: () => {
        dispatch(clearRouting());
        dispatch(clearAppData());
        dispatch(changeCurrentPage('lets-go'));
        dispatch(enableNavigationPanel());
      },
    },
    {
      text: button_statistics,
      modification: 'app-button--outline',
      clickFunc: () => {
        dispatch(changeCurrentPage('statistic'));
        dispatch(enableNavigationPanel());
      },
    },
    {
      text: button_share,
      modification: 'app-button--outline',
      clickFunc: () => shareNavigator(),
    },
    {
      text: button_to_the_menu,
      modification: 'app-button--outline',
      clickFunc: () => {
        dispatch(changeCurrentPage('main-menu'));
      },
    },
  ];

  // let allPoints = 0;
  // let rightPoints = 0;

  // chapters.forEach((chapter) => {
  //   const chapterAcf = chapter.acf;

  //   chapterAcf.steps.forEach((step) => {
  //     step.screens.forEach((screen) => {
  //       screen.sections_builder.forEach((section) => {
  //         if (section.acf_fc_layout === 'game') {
  //           const gameID = section.game[0];
  //           const game = appData.gamesResults[gameID];
  //           if (game) {
  //             allPoints += Number(game.allPoints);
  //             rightPoints += Number(game.rightPoints);
  //           } else {
  //             allPoints += 0;
  //             rightPoints += 0;
  //           }
  //         }
  //       });
  //     });
  //   });
  // });

  const allPoints = appData.pointsAll;
  const rightPoints = appData.pointsRight;

  const raplaceText = count_right_answers
    .replace('{{ALL}}', allPoints)
    .replace('{{RIGHT}}', rightPoints);

  const raplaceCongratulationsDescription = сongratulations_description.replace(
    '{{DATE}}',
    appData.completedDate
  );

  React.useEffect(() => {
    pushGtmEvent({
      event: 'StepReached',
      value: 'Game Completed',
      score: String(rightPoints),
    });
  }, []);

  return (
    <MainLayout>
      <div className={`${s['congratulations']} app-container`}>
        {сongratulations_heading && (
          <h1 className={`${s['congratulations__heading']} h1 text--red `}>
            {сongratulations_heading}
          </h1>
        )}
        {сongratulations_image && (
          <img
            className={s['congratulations__image']}
            src={сongratulations_image.url}
            alt={сongratulations_image.alt}
          />
        )}
        {count_right_answers && <p className={`${s['congratulations__count']}`}>{raplaceText}</p>}
        {сongratulations_description && (
          <div
            className={s['congratulations__description']}
            dangerouslySetInnerHTML={{ __html: raplaceCongratulationsDescription }}
          />
        )}

        <div className={s['congratulations__buttons']}>
          {buttons.map((button, i) => (
            <button
              key={i}
              onClick={() => button.clickFunc()}
              type='button'
              className={`app-button ${button.modification}`}>
              {button.text}
            </button>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export default Congratulations;
