import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Actions
import {
  changeCurrentPage,
  changeCurrentScreen,
  changeCurrentStep,
  changeCurrentChapter,
} from '@s/store/routing/actions';
import { changeAppData } from '@s/store/app-data/actions';
import { addQuizAnimation } from '@s/store/animations/actions';
import { showModalBeforeRedirect } from '@s/store/routing/actions';

// // Actions
// import { changeScreenType } from '@s/store/routing/actions';

const NextScreenButton = ({ children }) => {
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const cheat = queryParams.get('cheat');

  const dispatch = useDispatch();
  const [isRightDelay, setIsRightDelay] = React.useState(false);
  const navigation = useSelector((state) => state.navigation);
  const chapters = useSelector((state) => state.chapters).toJS();
  const chapter = useSelector((state) => state.chapter).toJS();
  const game = useSelector((state) => state.game).toJS();
  const { step, chapterID, is_show_modal_before_redirect } = useSelector(
    (state) => state.routing
  ).toJS();
  const appData = useSelector((state) => state.appData).toJS();
  const { currentStep, currentChapterId, completedChapters, currentChapter } = appData;
  const screenNext = navigation.get('screenNext');
  const { gamesResults } = appData;

  if (game.acf && cheat === null) {
    const game_type = game.acf.game_type;

    if (game_type === 'quiz') {
      const quizId = game.id;
      if (!(gamesResults[quizId] && gamesResults[quizId].answersIds.length)) {
        return false;
      }
    }

    if (game_type !== 'quiz') {
      const gameTypeId = game.id;
      if (!gamesResults[gameTypeId]) {
        return false;
      }
    }

    // text_input
    // geolocation
  }

  const handleOnClickScreenNext = () => {
    // console.log(screenNext);
    // Set Answers to Quiz
    if (game.acf) {
      const { game_type } = game.acf;

      if (game_type === 'text_input') {
        const gameTypeId = game.id;
        if (
          !gamesResults[gameTypeId].isAnswer &&
          gamesResults[gameTypeId].isRight &&
          !isRightDelay
        ) {
          return false;
        }
      } else if (game_type === 'quiz') {
        const quizId = game.id;

        if (!gamesResults[quizId].isAnswer && !isRightDelay) {
          const newAppData = {
            ...appData,
            gamesResults: { ...appData.gamesResults, ...(gamesResults[quizId].isAnswer = true) },
          };

          dispatch(changeAppData(newAppData));

          if (!isRightDelay) {
            if (gamesResults[quizId].isRight) {
              setIsRightDelay(true);

              setTimeout(() => {
                dispatch(showModalBeforeRedirect(true));
              }, 1000);

              setTimeout(() => {
                dispatch(showModalBeforeRedirect(false));
                setIsRightDelay(false);
                dispatch(changeCurrentScreen(screenNext));
              }, 3000);
            }
          }

          return false;
        } else {
          if (!(screenNext === 'finish' && step <= chapter.acf.steps.length) && !isRightDelay) {
            dispatch(addQuizAnimation(true));
            setTimeout(() => {
              dispatch(changeCurrentScreen(screenNext));
            }, 800);
            return false;
          }
        }
      }
    }

    if (screenNext === 'finish' && step <= chapter.acf.steps.length) {
      dispatch(changeCurrentStep(step + 1));
      dispatch(changeCurrentScreen(1));

      if (completedChapters.indexOf(chapterID) === -1) {
        if (currentStep <= step) {
          const newAppData = {
            ...appData,
            currentStep: currentStep + 1,
          };
          dispatch(changeAppData(newAppData));
        }
      }

      if (step >= chapter.acf.steps.length) {
        const newAppData = { ...appData };

        if (completedChapters.indexOf(chapterID) === -1) {
          newAppData.completedChapters = [...completedChapters, currentChapterId];
          newAppData.currentStep = 1;
          dispatch(changeAppData(newAppData));
        }

        if (currentChapterId === chapterID) {
          if (currentChapter <= chapters.length) {
            newAppData.currentChapter = currentChapter + 1;
            if (chapters[chapters.length - newAppData.currentChapter]) {
              dispatch(
                changeCurrentChapter(chapters[chapters.length - newAppData.currentChapter].id)
              );
            }
          }
        }
        dispatch(changeAppData(newAppData));
        dispatch(changeCurrentPage('chapters'));
      } else {
        // TODO: Need make animation for quiz
        dispatch(changeCurrentPage('chapter'));
      }
    } else {
      if (!isRightDelay) {
        dispatch(changeCurrentScreen(screenNext));
      }
    }
  };
  return (
    <div id='ButtonNext' onClick={handleOnClickScreenNext}>
      {children}
    </div>
  );
};

export default NextScreenButton;
